import { DefaultSeo } from 'next-seo'
import { Provider } from 'react-redux'
import { store, wrapper } from '../store/store'
import '../styles/globals.css'
import * as ga from '../lib/ga'
import { useRouter } from 'next/router'
import { useEffect } from 'react'


function MyApp({ Component, pageProps, err }) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <Provider store={store}>
        <DefaultSeo
          defaultTitle='Workay'
          description='Workay App'
          openGraph={{
            type: 'website',
            site_name: 'Workay',
          }}
          twitter={{
            handle: '@handle',
            site: '@site',
            cardType: 'summary_large_image',
          }} />
        <Component {...pageProps} err={err} />
      </Provider>
    </>
  )
}

export default wrapper.withRedux(MyApp);
